<div class="main-content-popup">
	<div *ngIf="data.firstTime" class="text-wrapper">
		<p class="sub-title">
			{{ "titles.fewMoreDetailsAboutDriver" | translate }}
		</p>
	</div>
	<form [formGroup]="form" class="applicant-form stage">
		<div class="questions-container">
			<div *ngIf="iSecondPageVisible" [ngStyle]="{ width: (currentPage * 100) / 2 + '%' }" class="progress-bar"></div>

			<ng-container *ngIf="currentPage === 1" [ngTemplateOutlet]="page1"></ng-container>
			<ng-container *ngIf="currentPage === 2" [ngTemplateOutlet]="page2"></ng-container>
		</div>

		<ng-template #page1>
			<div class="form-grid-layout first-page">
				<b class="basic-text">{{ "titles.basicInformation" | translate }}</b>

				<app-question-wrapper
					[show]="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
					class="first-name"
					icon="person"
				>
					<unmasked-input
						*ngIf="form.get(Fields.FirstName.name) && form.get(Fields.FirstName.name).enabled"
						[name]="Fields.FirstName.name"
						[pattern]="Fields.FirstName.pattern"
						[placeholder]="'personalInfo.firstName' | translate"
						maxlength="50"
						type="text"
					>
					</unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="form.get(Fields.LastName.name) && form.get(Fields.LastName.name).enabled"
					class="last-name"
					icon="person"
				>
					<unmasked-input
						[name]="Fields.LastName.name"
						[pattern]="Fields.LastName.pattern"
						[placeholder]="'personalInfo.lastName' | translate"
						maxlength="30"
						type="text"
					></unmasked-input>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="form.get(Fields.DOB.name) && form.get(Fields.DOB.name).enabled"
					class="date-of-birth"
					icon="calendar_today"
				>
					<masked-input
						[inputMask]="'**/**/9999'"
						[name]="Fields.DOB.name"
						[placeholder]="'personalInfo.dateOfBirth' | translate"
						class="date-input"
					>
					</masked-input>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="form.get(Fields.MaritalStatus.name) && form.get(Fields.MaritalStatus.name).enabled"
					class="maritial-status"
					icon="family_restroom"
				>
					<app-dropdown
						[labelInsideField]="'personalInfo.maritalStatus' | translate"
						[name]="Fields.MaritalStatus.name"
						[options]="FieldOptions.maritalStatusOptions"
						[reAlphabeticalOrder]="false"
						[tabindex]="form.get(Fields.MaritalStatus.name) && form.get(Fields.MaritalStatus.name).enabled ? 0 : -1"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="form.get(Fields.Gender.name) && form.get(Fields.Gender.name).enabled"
					class="gender"
					icon="person"
					optionalKeyText="labels.moreInfo"
					tooltipKeyText="tooltips.chooseOption"
				>
					<app-gender-dropdown
						[name]="Fields.Gender.name"
						[tabindex]="form.get(Fields.Gender.name) && form.get(Fields.Gender.name).enabled ? 0 : -1"
					></app-gender-dropdown>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="
						form.get(Fields.DriverRelationshipToDriver1.name) &&
						form.get(Fields.DriverRelationshipToDriver1.name).enabled
					"
					class="realationship-driver-1"
					icon="person"
				>
					<app-dropdown
						[labelInsideField]="'labels.relationshipToPrimaryDriver' | translate"
						[makeDropdownItemsTop]="true"
						[name]="Fields.DriverRelationshipToDriver1.name"
						[options]="FieldOptions.DriverRelationshipToDriver1Options"
						[reAlphabeticalOrder]="false"
						[tabindex]="
							form.get(Fields.DriverRelationshipToDriver1.name) &&
							form.get(Fields.DriverRelationshipToDriver1.name).enabled
								? 0
								: -1
						"
					></app-dropdown>
				</app-question-wrapper>

				<app-question-wrapper
					[show]="form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
					class="license-status"
					icon="person"
				>
					<app-dropdown
						[labelInsideField]="'personalInfo.driverLicenseStatus' | translate"
						[makeDropdownItemsTop]="true"
						[name]="Fields.DriverLicenseStatus.name"
						[options]="FieldOptions.DriverLicenseStatus"
						[reAlphabeticalOrder]="false"
						[tabindex]="
							form.get(Fields.DriverLicenseStatus.name) && form.get(Fields.DriverLicenseStatus.name).enabled ? 0 : -1
						"
					></app-dropdown>
				</app-question-wrapper>
			</div>

			<app-button
				(clicked)="goToNextPage()"
				*ngIf="iSecondPageVisible"
				[disabled]="!checkFieldsFirstStepValidity()"
				[title]="'buttons.nextStep' | translate"
				class="inner-next-button"
				materialIcon="chevron_right"
				severity="primary"
			></app-button>

			<ng-container *ngIf="!iSecondPageVisible" [ngTemplateOutlet]="submitButton"></ng-container>
		</ng-template>

		<ng-template #page2>
			<div class="form-grid-layout second-page">
				<ng-container
					*ngIf="
						form.get(Fields.DriverEmploymentIndustry.name)?.enabled ||
						form.get(Fields.DriverOccupationStr.name)?.enabled ||
						form.get(Fields.DriverEducation.name)?.enabled
					"
				>
					<b class="employment-text">
						{{ employmentEducationTitle | translate }}

						<button
							*ngIf="ifEmployeeTooltip"
							[attr.aria-label]="'tooltips.declineToProvide' | translate"
							[matTooltip]="'tooltips.declineToProvide' | translate"
							appHiddenWhenCssDisabled
							appToggleTooltip
							class="help-icon material-icons"
						>
							<span aria-hidden="true">help_outline</span>
						</button>
					</b>

					<ng-container *ngIf="form.get(Fields.DriverEmploymentIndustry.name)?.enabled">
						<app-question-wrapper
							[show]="form.get(Fields.DriverEmploymentIndustry.name)?.enabled"
							class="driver-employment-industry"
							icon="corporate_fare"
						>
							<app-dropdown
								[labelInsideField]="'labels.employmentIndustry' | translate"
								[name]="Fields.DriverEmploymentIndustry.name"
								[options]="DriverEmploymentIndustryEmployedOptions"
								[reAlphabeticalOrder]="false"
								[searchable]="true"
								[tabindex]="form.get(Fields.DriverLicenseStatus.name)?.enabled ? 0 : -1"
							></app-dropdown>
						</app-question-wrapper>
					</ng-container>

					<ng-container *ngIf="form.get(Fields.DriverOccupationStr.name)?.enabled">
						<app-question-wrapper
							[show]="form.get(Fields.DriverOccupationStr.name)?.enabled"
							class="occupation"
							icon="work"
						>
							<app-dropdown
								[disabled]="autoStateSpecificRules && form.get(Fields.DriverEmploymentIndustry.name).value === 'Other'"
								[labelInsideField]="'labels.occupation' | translate"
								[name]="Fields.DriverOccupationStr.name"
								[options]="occupationsOptions"
								[searchable]="true"
								[tabindex]="form.get(Fields.DriverLicenseStatus.name)?.enabled ? 0 : -1"
							></app-dropdown>
						</app-question-wrapper>
					</ng-container>

					<ng-container *ngIf="form.get(Fields.DriverEducation.name)?.enabled">
						<app-question-wrapper
							[show]="form.get(Fields.DriverEducation.name)?.enabled"
							class="education"
							icon="school"
						>
							<app-dropdown
								[labelInsideField]="'labels.driverEducation' | translate"
								[makeDropdownItemsTop]="true"
								[name]="Fields.DriverEducation.name"
								[options]="driverEducationOptions"
								[reAlphabeticalOrder]="false"
								[tabindex]="form.get(Fields.DriverEducation.name) && form.get(Fields.DriverLicenseStatus.name).enabled"
							></app-dropdown>
						</app-question-wrapper>
					</ng-container>
				</ng-container>
			</div>

			<ng-container [ngTemplateOutlet]="submitButton"></ng-container>
		</ng-template>

		<ng-template #submitButton>
			<div class="next-button-wrapper">
				<app-button
					(clicked)="handleAddEdit()"
					[disabled]="!this.form.valid"
					[title]="this.data.type == 'add' ? ('buttons.addDriver' | translate) : ('buttons.save' | translate)"
					materialIcon="check"
				></app-button>

				<button (click)="goToPreviousPage()" class="go-back">{{ "buttons.goBack" | translate }}</button>
			</div>
		</ng-template>
	</form>
</div>
