import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Fields } from 'src/app/constants/fields';
import { Validations } from 'src/app/utils/validation';
import { ActionApiService } from 'src/app/services/action-api.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/store/states/app.state';
import { hidePopupAction } from 'src/app/store/actions/popup.actions';
import { BasePageComponent } from '../../pages/base-page.component';
import { QuoteDataService } from 'src/app/services/quote-data.service';
import { v4 as uuidv4 } from 'uuid';
import { untilDestroyed } from '@ngneat/until-destroy';

import {
	AddManualDriver,
	EditDiscoveredDriver,
	EditDrivers,
	EditManualDriver,
} from 'src/app/store/actions/quote-data.actions';
import { FormattedDateService } from 'src/app/services/formatted-date.service';
import { DriverTypeEnum } from '../../pages/auto/drivers/model/driver-source.enum';
import { addDriversOpened } from 'src/app/store/actions/interview-metadata.actions';
import { IDropdownOption } from 'src/app/components/form-controls/form-control-interfaces';
import { IndustryModel } from 'src/entities/industry-api-response.interface';
import { Dictionary } from 'src/entities/key-value.interface';
import { toLowerCaseOccupation } from 'src/app/utils/general.utils';
import { selectAddress } from '../../../../store/selectors/quote-data.selectors';

@Component({
	selector: 'app-driver',
	templateUrl: './driver-popup.component.html',
	styleUrls: ['./driver-popup.component.scss'],
})
export class DriverPopupComponent extends BasePageComponent implements OnInit {
	form;
	actionApiService: ActionApiService;
	data: any;
	DriverEmploymentIndustryEmployedOptions: IDropdownOption[];
	industries: IndustryModel[];
	occupationsOptions: IDropdownOption[];
	driverEducationOptions: IDropdownOption[];
	currentPage: number = 1;
	selectedState: string;
	ifEmployeeTooltip = false;
	iSecondPageVisible = true;
	employmentEducationTitle = 'titles.employmentEducation';

	private fieldsFirstStep = [
		Fields.FirstName.name,
		Fields.LastName.name,
		Fields.DOB.name,
		Fields.Gender.name,
		Fields.MaritalStatus.name,
		Fields.DriverRelationshipToDriver1.name,
		Fields.DriverLicenseStatus.name,
	];

	constructor(
		actionApiService: ActionApiService,
		injector: Injector,
		protected quoteDataService: QuoteDataService,
		private formattedDateService: FormattedDateService,
		protected store: Store<IAppState>
	) {
		super(injector, store);
		this.actionApiService = actionApiService;
	}

	get autoStateSpecificRules(): boolean {
		return this.d2CFeatures?.autoStateSpecificRules;
	}

	get occupationControl(): FormControl {
		return this.form.controls.DriverOccupationStr as FormControl;
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.initIndustries();

		if (this.data) {
			this.initform(this.data);

			if (this.data.type == 'edit') {
				this.store.dispatch(addDriversOpened({ Id: this.data.data.Id }));
			}
		}

		setTimeout(() => {
			this.driverEducationOptions = [...this.FieldOptions.DriverEducationOptions];
			this.handleOccupationVisibility();
		});
	}

	initform(data?): void {
		this.form = new FormGroup({});

		this.form.addControl(
			Fields.FirstName.name,
			new FormControl({ value: data?.data?.FirstName || null, disabled: false }, [
				Validators.required,
				Validators.maxLength(50),
				Validations.firstNameOneChar,
				Validations.alphabet,
			])
		);

		this.form.addControl(
			Fields.LastName.name,
			new FormControl({ value: data?.data?.LastName, disabled: false }, [
				Validators.required,
				Validators.maxLength(30),
				Validations.lastNameOneChar,
				Validations.alphabet,
			])
		);

		this.form.addControl(
			Fields.Gender.name,
			new FormControl({ value: data?.data?.Gender || null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DOB.name,
			new FormControl({ value: this.formattedDateService.findYearOnDate(data?.data?.DOB), disabled: false }, [
				Validators.required,
				Validations.isDOBpastDate,
				Validations.dateFormat,
				Validations.isMoreThan16,
				Validations.isEqulesOrAfter1920,
				Validations.unmaskedDOB,
			])
		);

		this.form.addControl(
			Fields.MaritalStatus.name,
			new FormControl({ value: data?.data?.MaritalStatus || null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverRelationshipToDriver1.name,
			new FormControl({ value: data?.data?.DriverRelationshipToDriver1 || null, disabled: false }, [
				Validators.required,
			])
		);

		this.form.addControl(
			Fields.DriverLicenseStatus.name,
			new FormControl({ value: data?.data?.DriverLicenseStatus || null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverEmploymentIndustry.name,
			new FormControl({ value: data?.data?.DriverEmploymentIndustry || null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverOccupationStr.name,
			new FormControl({ value: data?.data?.DriverOccupationStr || null, disabled: false }, [Validators.required])
		);

		this.form.addControl(
			Fields.DriverEducation.name,
			new FormControl({ value: data?.data?.DriverEducation || null, disabled: false }, [Validators.required])
		);

		this.form.controls.DriverEmploymentIndustry?.valueChanges.subscribe((currentIndustry) => {
			if (!currentIndustry) {
				this.occupationsOptions = [];
			} else {
				this.form.controls.DriverOccupationStr.setValue(null);
				this.form.controls.DriverOccupationStr.markAsUntouched();
				this.occupationsOptions = this.mapToDropdownValues(
					this.industries?.find((i) => i.key === currentIndustry).occupations
				);
			}

			this.setOccupationsAndEducationsOptions();
		});

		if (this.data.data && this.data.data.formErrors) {
			setTimeout(() => {
				this.data.data.formErrors.forEach((formErrorsEntry) => {
					if (formErrorsEntry.errors && this.form.controls[formErrorsEntry.name]) {
						this.form.controls[formErrorsEntry.name].setErrors(formErrorsEntry.errors);
						this.form.controls[formErrorsEntry.name].markAsTouched();
					}
				});
			});
		}
	}

	handleAddEdit() {
		//Adds the driver to manual Drivers
		if (this.data.type == 'add') {
			const Id = uuidv4();
			let driver = {
				data: {
					...this.form.value,
					Id: Id,
					SequenceNum: this.data.max ? -1 : this.data.SequenceNum,
					DOB: this.formattedDateService.formatDateIfItIsNotMasked(this.form.value.DOB),
					active: true,
				},
			};
			this.store.dispatch(AddManualDriver(driver));
			this.store.dispatch(addDriversOpened({ Id: Id }));
			this.quoteDataService.addManualDriver.next({ driver: driver });
		} else {
			let driver = {
				data: {
					...this.form.value,
					Id: this.data.data.Id,
					DOB: this.formattedDateService.formatDateIfItIsNotMasked(this.form.value.DOB),
				},
			};
			if (this.data.source == DriverTypeEnum.Manual) {
				this.store.dispatch(EditManualDriver(driver));
			} else if (this.data.source == DriverTypeEnum.Discover) {
				this.store.dispatch(EditDiscoveredDriver(driver));
			} else {
				this.store.dispatch(EditDrivers(driver));
			}
			this.quoteDataService.updateDriver.next({ driver: driver, source: this.data.source });
		}
		this.store.dispatch(hidePopupAction());
	}

	close() {
		this.store.dispatch(hidePopupAction());
	}

	goToNextPage(): void {
		this.currentPage++;
	}

	goToPreviousPage(): void {
		if (this.currentPage > 1) {
			this.currentPage--;
		}
	}

	checkFieldsFirstStepValidity(): boolean {
		for (const fieldName of this.fieldsFirstStep) {
			const field = this.form.get(fieldName);
			if (!field || !field.valid) {
				return false;
			}
		}
		return true;
	}

	private initIndustries(): void {
		this.actionApiService
			.getIndustriesAndOccupations()
			.pipe(untilDestroyed(this))
			.subscribe((data) => {
				this.industries = data.industries;
				const selectedIndustry = this.form.controls.DriverEmploymentIndustry.value;

				if (this.industries) {
					this.DriverEmploymentIndustryEmployedOptions = this.mapToDropdownValues(
						this.industries.map((i) => ({ key: i.key, value: i.value }))
					);

					this.handleOccupationVisibility();
				}

				if (selectedIndustry) {
					this.occupationsOptions = this.mapToDropdownValues(
						this.industries?.find((i) => i.key === this.form.controls.DriverEmploymentIndustry.value).occupations
					);
				}

				this.setOccupationsAndEducationsOptions(this.occupationsOptions);
			});
	}

	private handleOccupationVisibility(): void {
		if (!this.autoStateSpecificRules) {
			return;
		}

		this.store
			.select(selectAddress)
			.pipe(untilDestroyed(this))
			.subscribe(({ State }) => {
				this.selectedState = State;

				if (['MA', 'DC', 'PA'].includes(State)) {
					this.form.controls[Fields.DriverOccupationStr.name].disable();
					this.form.controls[Fields.DriverEmploymentIndustry.name].disable();
					this.employmentEducationTitle = 'titles.employmentInformation';

					if (State === 'MA') {
						this.form.controls[Fields.DriverEducation.name].disable();
					}
					this.iSecondPageVisible = false;
				} else if (['NY', 'MI', 'CA'].includes(State)) {
					this.setOccupationsAndEducationsOptions();

					if (State === 'CA') {
						this.ifEmployeeTooltip = true;
					}

					this.form.controls[Fields.DriverEmploymentIndustry.name].valueChanges
						.pipe(untilDestroyed(this))
						.subscribe((value) => {
							this.form.controls[Fields.DriverOccupationStr.name].setValue(value === 'Other' ? 'Other' : null);
						});
				}
			});
	}

	private setOccupationsAndEducationsOptions(
		occ: IDropdownOption[] = this.occupationsOptions,
		ed: IDropdownOption[] = this.driverEducationOptions,
		ind: IDropdownOption[] = this.DriverEmploymentIndustryEmployedOptions
	) {
		if (!this.autoStateSpecificRules) {
			return;
		}

		let addOpt = {
			id: 0,
			value: '',
			title: 'I decline to provide',
		};

		if (['NY', 'MI', 'CA'].includes(this.selectedState)) {
			if (occ?.length && !occ.filter(({ title }) => title === addOpt?.title)?.length) {
				this.occupationsOptions = [...occ, { ...addOpt, value: this.getCantProvideValue() }];
				this.occupationControl.value && this.occupationControl.setValue(this.getCantProvideValue());
			}
			if (ed?.length && !ed.filter(({ title }) => title === addOpt?.title)?.length) {
				this.driverEducationOptions = [...ed, { ...addOpt, value: 'Bachelors' }];
			}
			if (ind?.length && !ind.filter(({ title }) => title === addOpt?.title)?.length) {
				this.DriverEmploymentIndustryEmployedOptions = [...ind, { ...addOpt, value: 'Other' }];
			}
		} else {
			this.occupationsOptions = (occ || []).filter(({ title }) => title !== addOpt?.title);
			this.driverEducationOptions = (ed || []).filter(({ title }) => title !== addOpt?.title);
			this.DriverEmploymentIndustryEmployedOptions = (ind || []).filter(({ title }) => title !== addOpt?.title);
		}
	}

	private getCantProvideValue(): string {
		const selectedIndustry = this.form.controls.DriverEmploymentIndustry.value;

		return ['Disabled', 'Retired', 'Unemployed', 'Homemaker/Houseprsn'].includes(selectedIndustry)
			? selectedIndustry
			: 'Other';
	}

	private mapToDropdownValues(dataArray: Dictionary<string>[]): IDropdownOption[] {
		if (dataArray) {
			return dataArray.map(
				(data, index) =>
				({
					id: ++index,
					title: toLowerCaseOccupation(data.value),
					value: data.key,
				} as IDropdownOption)
			);
		}
	}
}
