import { Component, Input, OnInit } from '@angular/core';
import { BaseFormControlComponent } from '../../form-controls/base-form-control.component';
import { IDropdownOption } from '../../form-controls/form-control-interfaces';
import { selectQuoteData } from 'src/app/store/selectors/quote-data.selectors';
import { IQuoteDataState } from 'src/app/store/states/quote-data.state';
import { Observable, map } from 'rxjs';

@Component({
	selector: 'app-gender-dropdown',
	templateUrl: './gender-dropdown.component.html',
})
export class GenderDropdownComponent extends BaseFormControlComponent implements OnInit {
	@Input() name: string;
	@Input() label: string;

	genderOptions$: Observable<IDropdownOption[]>;

	private readonly genderOptionsWithoutGenderX: IDropdownOption[] = [
		{ id: 1, value: 'Male', title: 'Male', labelKey: 'gender.male' },
		{ id: 2, value: 'Female', title: 'Female', labelKey: 'gender.female' },
	];

	private readonly genderOptionsWithGenderX: IDropdownOption[] = [
		...this.genderOptionsWithoutGenderX,
		{ id: 3, value: 'NotSpecified', title: 'Gender X', labelKey: 'gender.genderX' },
	];

	private readonly statesWithGenderX = ['CA', 'CO', 'DC', 'MA', 'NY', 'OR', 'VA', 'WA'];

	ngOnInit(): void {
		super.ngOnInit();

		this.genderOptions$ = this.store
			.select(selectQuoteData)
			.pipe(
				map((quoteData: IQuoteDataState) =>
					this.statesWithGenderX.includes(quoteData.PropertyAddress.State)
						? this.genderOptionsWithGenderX
						: this.genderOptionsWithoutGenderX
				)
			);
	}
}
